import { ThreadMessage } from "@core/services/nocd-api";
import {
  ChannelWhisper,
  useChatChannelWhispers,
} from "@features/messaging/hooks/useChatChannelWhispers";
import { useMemberSawTherapistChatAt } from "@features/messaging/hooks/v3/useMemberSawTherapistChatAt";
import { useThread } from "@features/messaging/hooks/v3/useThread";
import { usePostThreadMessage } from "@features/messaging/mutations/v3/usePostThreadMessage";
import { getMessageColor, isWhisper } from "@features/messaging/utils";
import { DocumentIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Visibility as VisibilityIcon } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { indigo } from "@mui/material/colors";
import { format } from "date-fns";
import { sortBy } from "lodash";
import { last } from "lodash/fp";
import { FC, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import Linkify from "react-linkify";
import { Rings } from "react-loader-spinner";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  channelId: number;
  memberId: number;
  preloadMessage?: string;
}

const MessagingSpotlight: FC<Props> = ({
  isOpen,
  onClose,
  channelId,
  memberId,
  preloadMessage,
}) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const { data: thread, isLoading: isLoadingThread } = useThread(
    channelId,
    memberId
  );
  const { data: memberSeenAt, isLoading: isLoadingMemberSeenAt } =
    useMemberSawTherapistChatAt(memberId, channelId);
  const { data: whispers, isLoading: isLoadingWhispers } =
    useChatChannelWhispers(channelId);

  const postThreadMessage = usePostThreadMessage();

  const [message, setMessage] = useState(preloadMessage || "");

  const allConversationItems = [
    ...(thread?.messages || []),
    ...(whispers?.length > 0 ? whispers : [null]),
  ].filter(Boolean);

  const orderedMessages = sortBy(allConversationItems, `created_at`);

  const messagesSeen = thread?.messages.filter(
    (x) => x.created_at < memberSeenAt?.seen_at
  );
  const lastSeenMessageId = last(messagesSeen)?.id;

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView(true);
  }, [thread]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isOpen}
      scroll="paper"
      onClose={onClose}
      PaperProps={{
        sx: {
          position: { md: "absolute" },
          right: { md: "24px" },
          borderRadius: "16px",
          height: "100%",
        },
      }}
    >
      {thread ? (
        <DialogTitle>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              flexDirection="column"
              // alignItems="center"
              gap={1}
            >
              <Box display="flex" alignItems="center" gap={1}>
                <Avatar
                  sx={{
                    width: 30,
                    height: 30,
                  }}
                  src={thread?.image_url}
                  alt="member avatar"
                />

                <Typography variant="h6">{thread?.display_name}</Typography>
              </Box>
            </Box>

            <IconButton onClick={onClose}>
              <XMarkIcon style={{ width: 24, height: 24 }} />
            </IconButton>
          </Box>
        </DialogTitle>
      ) : null}

      <DialogContent
        sx={{
          pb: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {isLoadingThread || isLoadingMemberSeenAt || isLoadingWhispers ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Rings color="blue" height={125} width={125} />
          </Box>
        ) : (
          <Stack
            justifyContent="flex-end"
            direction="column"
            spacing={2}
            flexGrow={1}
          >
            <Box sx={{ overflowY: "scroll", pr: 1 }}>
              {orderedMessages.map((m) => {
                const whisperItem = m as ChannelWhisper;
                if (isWhisper(whisperItem)) {
                  return (
                    <Box
                      flexGrow={1}
                      flexDirection="column"
                      key={whisperItem.id}
                      my={1.5}
                    >
                      <Alert severity="info" color="warning">
                        <AlertTitle sx={{ textAlign: "left" }}>
                          <Typography variant="subtitle2" sx={{ mt: 0.4 }}>
                            Whisper
                          </Typography>
                        </AlertTitle>
                        <Typography
                          variant="body2"
                          sx={{
                            wordBreak: "break-word",
                            whiteSpace: "pre-wrap",
                            textAlign: "left",
                          }}
                        >
                          {whisperItem.content}
                        </Typography>
                      </Alert>
                    </Box>
                  );
                }

                const messageColor = getMessageColor(m as ThreadMessage);
                const isSentFromClinical =
                  m.sent_by_user_type !== "premium_users";
                const imageHeight = m.data?.aspect_ratio
                  ? 140 / m.data?.aspect_ratio
                  : null;
                const isLastSeenMessage = m.id === lastSeenMessageId;

                return (
                  <Box key={m.id} display="flex" gap={1}>
                    <Avatar
                      sx={{
                        width: 36,
                        height: 36,
                        mt: 0.5,
                      }}
                      src={m.avatar_url}
                      alt={m.sent_by_user_email}
                    />

                    <Box flexGrow={1} my={0.5}>
                      <Box
                        p={1.5}
                        pb={0.5}
                        bgcolor={messageColor}
                        borderRadius={4}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            wordBreak: "break-word",
                            whiteSpace: "pre-wrap",
                            marginBottom: "8px",
                          }}
                        >
                          <Linkify>{m.content}</Linkify>
                        </Typography>

                        {m.data?.image_url && (
                          <Box
                            p={1}
                            width={150}
                            height={imageHeight + 10}
                            display="flex"
                            alignItems="center"
                          >
                            {/* eslint-disable-next-line @next/next/no-img-element */}
                            <img
                              src={m.data.image_url}
                              className="object-cover"
                              width={140}
                              height={imageHeight}
                              alt="attached"
                            />
                          </Box>
                        )}

                        {m?.data?.attachments && (
                          <Box
                            component="a"
                            href={m.data?.attachments[0]?.url}
                            target="_blank"
                            bgcolor="#FAFAFA"
                            borderRadius={4}
                            p={2}
                            my={2}
                            mx={1}
                            gap={1}
                            minWidth={170}
                            display="flex"
                            alignItems="center"
                            sx={{
                              textDecoration: "none",
                              ":hover": {
                                bgcolor: "#F0F0F0",
                              },
                            }}
                          >
                            <DocumentIcon
                              style={{
                                color: indigo[500],
                                height: "46px",
                                width: "46px",
                              }}
                            />
                            <Box>
                              <Typography variant="body1" mb={-1}>
                                {m?.data?.attachments[0]?.name}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="gray"
                                sx={{
                                  textDecoration: "none",
                                  textAlign: "left",
                                }}
                              >
                                PDF file
                              </Typography>
                            </Box>
                          </Box>
                        )}

                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                        >
                          <Typography variant="caption" display="inline">
                            {format(new Date(m.created_at), `p`)}
                          </Typography>
                        </Box>
                      </Box>

                      {isLastSeenMessage &&
                        isSentFromClinical &&
                        memberSeenAt?.seen_at && (
                          <Box display="flex" justifyContent="flex-end">
                            <VisibilityIcon
                              sx={{ marginRight: 0.7, marginTop: 0.8 }}
                              fontSize="small"
                            />
                            <Typography sx={{ marginTop: 1 }} variant="caption">
                              Seen{" "}
                              {format(new Date(memberSeenAt?.seen_at), `PP p`)}
                            </Typography>
                          </Box>
                        )}
                    </Box>
                  </Box>
                );
              })}
            </Box>
            <div id="conversationEndRef" ref={messagesEndRef} />
          </Stack>
        )}
      </DialogContent>

      <DialogActions>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          gap={1}
          mb={1}
          p={2}
          pt={0}
        >
          <Divider />

          {preloadMessage ? (
            <>
              <Typography fontWeight="bold" sx={{ pt: 0.5 }}>
                <span role="img">✨</span> AI Suggested Welcome Message
              </Typography>
              <Typography variant="subtitle2" color="gray">
                AI uses Intake data for a personalized welcome. Please review
                before sending, as NOTO AI can make mistakes.
              </Typography>
            </>
          ) : null}

          <TextField
            placeholder="Enter message..."
            fullWidth
            multiline
            onChange={(event) => {
              setMessage(event.target.value);
            }}
            value={message}
            disabled={postThreadMessage.isLoading}
            maxRows={6}
            minRows={6}
            margin="dense"
          />

          <Button
            variant="contained"
            disableElevation
            fullWidth
            onClick={async () => {
              const promise = postThreadMessage
                .mutateAsync({
                  channelId,
                  memberId,
                  content: message,
                  threadsQueryKey: null,
                  threadKey: `thread-${channelId}-${memberId}`,
                  shouldArchive: false,
                  attachmentType: null,
                  attachmentUrl: null,
                  attachmentAspectRatio: null,
                  attachmentTitle: null,
                  size: null,
                  policy: null,
                  signature: null,
                })
                .then(() => setMessage(""));

              return toast.promise(promise, {
                loading: "Sending message...",
                success: "Message sent!",
                error: "Failed to send message.",
              });
            }}
            disabled={
              isLoadingMemberSeenAt ||
              isLoadingThread ||
              postThreadMessage.isLoading
            }
          >
            {postThreadMessage.isLoading ? (
              <LinearProgress sx={{ width: "100%" }} />
            ) : (
              "Send"
            )}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default MessagingSpotlight;
