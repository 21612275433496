import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";

import { Task } from "../hooks/useClinicianTasks";
import useDeleteCustomTask from "../hooks/useDeleteCustomTask";
import useEditCustomTask from "../hooks/useEditCustomTask";

const schema = yup.object({
  title: yup.string().required("Required"),
});

interface EditCustomTaskDialogProps {
  open: boolean;
  onClose: () => void;
  task: Task;
}

const EditCustomTaskDialog: React.FC<EditCustomTaskDialogProps> = ({
  open,
  onClose,
  task,
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: "",
    },
  });

  const { mutateAsync: deleteTask, isLoading: isDeleting } =
    useDeleteCustomTask();
  const { mutateAsync: editTask, isLoading: isUpdating } = useEditCustomTask();

  const handleDeleteTask = () => {
    return toast.promise(
      deleteTask({
        id: task.id,
        clinician_email: task.clinician_email,
      }).then(() => onClose()),
      {
        loading: "Removing task...",
        success: "Task removed successfully!",
        error: "Failed to remove the task",
      }
    );
  };
  useEffect(() => {
    if (task) {
      setValue("title", task.title);
    }
  }, [task, setValue]);

  const handleEditSubmit = handleSubmit((formData) => {
    return toast
      .promise(
        editTask({
          id: task.id,
          clinician_email: task.clinician_email,
          title: formData.title,
        }),
        {
          loading: "Updating task...",
          success: "Task updated successfully!",
          error: "Failed to update task",
        }
      )
      .then(() => onClose());
  });

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleEditSubmit}>
        <DialogTitle>Edit to-do</DialogTitle>
        <DialogContent>
          <Box mt={2}>
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Description"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="E.g., Follow up with member, Complete session notes..."
                  fullWidth
                  variant="outlined"
                  error={!!errors.title}
                  helperText={errors.title?.message}
                  disabled={isUpdating || isDeleting}
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeleteTask}
            disabled={isUpdating || isDeleting}
            color="error"
          >
            Delete
          </Button>
          <Button type="submit" disabled={isUpdating || isDeleting}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditCustomTaskDialog;
