import { useSession } from "@core/hooks/useSession";
import { api } from "@core/services/nocd-api";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";

import { getClinicianTasksQueryKey } from "./useClinicianTasks";

interface EditPayload {
  id: string;
  clinician_email: string;
  title: string;
}

const useEditCustomTask = (): UseMutationResult => {
  const queryClient = useQueryClient();
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useMutation(
    (payload: EditPayload) =>
      api.post(
        `/v1/clinicians/${payload.clinician_email}/tasks/edit_custom_task`,
        payload,
        {
          headers: {
            Authorization: accessToken,
          },
        }
      ),
    {
      onSuccess: async (_data, payload) => {
        await queryClient.invalidateQueries(
          getClinicianTasksQueryKey(payload.clinician_email)
        );
      },
    }
  );
};

export default useEditCustomTask;
