import { Task } from "@features/clinician-tasks/hooks/useClinicianTasks";
import { ArrowRightAltOutlined } from "@mui/icons-material";
import { Box, Chip, IconButton, Typography } from "@mui/material";
import { useState } from "react";

import MessagingSpotlight from "./MessagingSpotlight";

interface MessagingSpotlightButtonProps {
  task: Task;
  title?: string;
  variant?: string;
}

const MessagingSpotlightButton = ({
  task,
  title,
  variant = "icon",
}: MessagingSpotlightButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);

  if (!task) {
    return null;
  }

  const [channelId, memberId] = task.link_url.split("-");

  if (variant === "row") {
    return (
      <Box
        width="100%"
        border="1px solid rgba(0, 0, 0, 0.12)"
        padding="0px 16px"
        height="48px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        onClick={() => setIsOpen(!isOpen)}
        sx={{
          ":hover": {
            cursor: "pointer",
          },
        }}
      >
        <Box display="flex">
          <Typography variant="body1" fontWeight={500} sx={{ mr: 1 }}>
            {title}
          </Typography>
          <Chip
            label="1"
            size="small"
            sx={{ backgroundColor: "#ed6c02", color: "white" }}
          />
        </Box>

        <Box>
          <IconButton onClick={() => setIsOpen(true)}>
            <ArrowRightAltOutlined />
          </IconButton>

          {isOpen ? (
            <MessagingSpotlight
              isOpen={isOpen}
              onClose={() => setIsOpen(false)}
              channelId={+channelId}
              memberId={+memberId}
              preloadMessage={task.link_text}
            />
          ) : null}
        </Box>
      </Box>
    );
  }

  return (
    <>
      <IconButton onClick={() => setIsOpen(true)}>
        <ArrowRightAltOutlined />
      </IconButton>

      {isOpen ? (
        <MessagingSpotlight
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          channelId={+channelId}
          memberId={+memberId}
          preloadMessage={task.link_text}
        />
      ) : null}
    </>
  );
};

export default MessagingSpotlightButton;
