import { useSession } from "@core/hooks/useSession";
import {
  api,
  Thread as IThread,
  transformAPIError,
} from "@core/services/nocd-api";
import { useQuery, useQueryClient, UseQueryResult } from "react-query";

// A compound key of channel id and member id is used while we still support v1 therapy chat
// where the channel id is always -1
export const getQueryKey = (channelId: number, memberId: number): string =>
  `thread-${channelId}-${memberId}`;

export const getThread = async ({
  threadId,
  memberId,
  targetMessageId,
  accessToken,
  signal,
  audit_email,
}: {
  threadId: number;
  memberId: number;
  targetMessageId: number;
  accessToken: string;
  signal?: AbortSignal;
  audit_email?: string | null;
}) =>
  api
    .get<IThread>(`v3/clinician/chat/channel`, {
      params: {
        channel_id: threadId,
        member_id: memberId,
        target_message_id: targetMessageId,
        audit_email,
      },
      headers: {
        authorization: accessToken,
      },
      signal,
    })
    .then((res) => {
      const { data } = res;
      return data;
    })
    .catch(transformAPIError);

export const useThread = (
  threadId: number,
  memberId: number,
  targetMessageId?: number,
  threadsQueryKey?: string,
  audit_email?: string | null
): UseQueryResult<IThread, Error> => {
  const { data: session } = useSession();
  const accessToken = session?.accessToken;
  const queryClient = useQueryClient();

  return useQuery(
    getQueryKey(threadId, memberId),
    async ({ signal }) => {
      return getThread({
        threadId,
        memberId,
        targetMessageId,
        accessToken,
        signal,
        audit_email,
      });
    },
    {
      enabled:
        !!accessToken && threadId !== undefined && memberId !== undefined,
      refetchInterval: 30 * 1000, // 30 seconds
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      onSuccess: (_response) => {
        if (threadsQueryKey && !audit_email) {
          queryClient.setQueryData(
            [threadsQueryKey],
            (threadsList: IThread[]) => {
              return (threadsList || []).map((thread) => {
                // linked threads are returned as a field within the thread object
                // for each clinicians assigned members so we need to check each
                // nested list to turn the unread flag to unresolved on thread load
                const updatedLinkedThreads = (thread.linked_threads || []).map(
                  (subthread) => {
                    if (subthread.id === threadId) {
                      return {
                        ...subthread,
                        unread_count: 0,
                      };
                    }
                    return subthread;
                  }
                );

                return thread.id === threadId
                  ? {
                      ...thread,
                      unread_count: 0,
                      linked_threads: updatedLinkedThreads,
                    }
                  : { ...thread, linked_threads: updatedLinkedThreads };
              });
            }
          );
        }
      },
    }
  );
};
