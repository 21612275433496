import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import * as yup from "yup";

import useCreateCustomTask from "../hooks/useCreateCustomTask";

const schema = yup.object({
  title: yup.string().required("Required"),
});

interface AddTaskDialogProps {
  open: boolean;
  onClose: () => void;
  clinicianEmail: string;
}

const AddTaskDialog: React.FC<AddTaskDialogProps> = ({
  open,
  onClose,
  clinicianEmail,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { title: "" },
  });

  const { mutateAsync: create, isLoading: isCreatingTask } =
    useCreateCustomTask();

  const handleDialogClose = () => {
    reset();
    onClose();
  };

  const handleAddTaskSubmit = handleSubmit(({ title }: { title: string }) => {
    return toast
      .promise(create({ title, clinician_email: clinicianEmail }), {
        loading: "Adding task...",
        success: "Task added successfully!",
        error: "Failed to add the task",
      })
      .then(() => {
        handleDialogClose();
      });
  });

  return (
    <Dialog open={open} onClose={handleDialogClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleAddTaskSubmit}>
        <DialogTitle>Add new To-do</DialogTitle>
        <DialogContent>
          <Box mt={2}>
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Description"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={!!errors.title}
                  disabled={isCreatingTask}
                  helperText={errors.title?.message}
                  fullWidth
                  placeholder="E.g., Follow up with member, Complete session notes..."
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} disabled={isCreatingTask}>
            Cancel
          </Button>
          <Button type="submit" disabled={isCreatingTask}>
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddTaskDialog;
