import { useSession } from "@core/hooks/useSession";
import { api, DefaultResponse } from "@core/services/nocd-api";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";

import {
  ClinicianTasksResponse,
  getClinicianTasksQueryKey,
  TaskType,
} from "./useClinicianTasks";

interface SnoozePayload {
  id: string;
  clinician_email: string;
  type: TaskType;
  remind_at: string;
}

const useSnooze = (): UseMutationResult<
  DefaultResponse,
  Error,
  SnoozePayload,
  {
    rollback: ClinicianTasksResponse;
  }
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const qc = useQueryClient();

  return useMutation(
    (payload) =>
      api.post(
        `/v1/clinicians/${payload.clinician_email}/tasks/snooze`,
        payload,
        {
          headers: {
            Authorization: accessToken,
          },
        }
      ),
    {
      onMutate: (payload) => {
        const qk = getClinicianTasksQueryKey(payload.clinician_email);

        const current = qc.getQueryData<ClinicianTasksResponse>(qk);

        if (current) {
          const updatedTaskGroups = current.task_groups.map((group) => {
            const updatedTasks = group.tasks.map((task) => {
              if (task.id === payload.id) {
                return {
                  ...task,
                  is_snoozed: true,
                };
              }
              return task;
            });

            return {
              ...group,
              tasks: updatedTasks,
            };
          });

          qc.setQueryData<ClinicianTasksResponse>(qk, {
            ...current,
            task_groups: updatedTaskGroups,
          });
        }

        return {
          rollback: current,
        };
      },
      onError: (_err, variables, ctx) => {
        // Roll back the optimistic update
        if (ctx) {
          const { rollback } = ctx;

          if (rollback) {
            qc.setQueryData(
              getClinicianTasksQueryKey(variables.clinician_email),
              rollback
            );
          }
        }
      },
    }
  );
};

export default useSnooze;
