import { useSession } from "@core/hooks/useSession";
import { api } from "@core/services/nocd-api";
import { useQuery, UseQueryResult } from "react-query";

interface SeenAt {
  seen_at: string | null;
  error: string | null;
}

export const getMemberSeenAt = async ({
  memberId,
  threadId,
  accessToken,
}: {
  memberId: number;
  threadId: number;
  accessToken: string;
}) =>
  api
    .get<SeenAt>(`v3/clinician/chat/channel/seen_at`, {
      params: {
        member_id: memberId,
        thread_id: threadId,
      },
      headers: {
        authorization: accessToken,
      },
    })
    .then((res) => {
      const { data } = res;
      return data;
    })
    .catch(() => ({ seen_at: null, error: "Error fetching member seen at" }));

export const useMemberSawTherapistChatAt = (
  memberId: number,
  threadId: number
): UseQueryResult<SeenAt, Error> => {
  const { data: session } = useSession();
  const accessToken = session?.accessToken;

  return useQuery(
    `thread-seen-at-${memberId}-${threadId}`,
    async () => {
      return getMemberSeenAt({
        memberId,
        threadId,
        accessToken,
      });
    },
    {
      enabled:
        !!accessToken && memberId !== undefined && threadId !== undefined,
    }
  );
};
