import { useSession } from "@core/hooks/useSession";
import { api } from "@core/services/nocd-api";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";

import { getClinicianTasksQueryKey } from "./useClinicianTasks";

interface CreatePayload {
  title: string;
  clinician_email: string;
}

const useCreateCustomTask = (): UseMutationResult => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();

  return useMutation(
    (payload: CreatePayload) =>
      api.post(
        `/v1/clinicians/${payload.clinician_email}/tasks/create_custom_task`,
        payload,
        {
          headers: {
            Authorization: accessToken,
          },
        }
      ),

    {
      onSuccess: async (_data, payload) => {
        await queryClient.invalidateQueries(
          getClinicianTasksQueryKey(payload.clinician_email)
        );
      },
    }
  );
};

export default useCreateCustomTask;
