import {
  ChatEvents,
  MessageMemberAdvocate,
  ThreadMessage,
} from "@core/services/nocd-api";
import { format } from "date-fns";

import { ChannelWhisper } from "./hooks/useChatChannelWhispers";

export const isWhisper = (
  message: MessageMemberAdvocate | ChatEvents | ChannelWhisper
): message is ChannelWhisper => !!message.is_whisper;

export const formatMessages = (messages: ThreadMessage[]) =>
  messages.map((message) => ({
    ...message,
    day: format(new Date(message.created_at), `PP`),
    time: format(new Date(message.created_at), `p`),
  }));

export const getAutomatedMessageLabel = (message: ThreadMessage) => {
  switch (message.automated_message_type) {
    case "broadcast":
      return "* BROADCAST";
    case "cancellation_detection_confirm":
      return "* CANCELLATION BOT";
    case "automated":
      return "* AUTOMATED";
    default:
      return null;
  }
};

export const getMessageColor = (message: ThreadMessage) => {
  switch (message.sent_by_user_type) {
    case "premium_clinicians":
      switch (message.automated_message_type) {
        case "broadcast":
          return `#00d3e0`;
        case "cancellation_detection_confirm":
          return `pink`;
        case "automated":
          return `lightgray`;
        default:
          return `lightblue`;
      }
    case "clinical_leadership":
      return `thistle`;
    case "premium_users":
      return `lightgreen`;
    default:
      return `lightgray`;
  }
};
